var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-component" }, [
    _c(
      "form",
      [
        _c(
          "button",
          {
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.toggleOpen = !_vm.toggleOpen
              }
            }
          },
          [_vm._v("Toggle field")]
        ),
        _vm.toggleOpen
          ? _c("div", { staticClass: "field" }, [
              _vm._m(0),
              _c("p", {
                staticClass: "help",
                attrs: { "validate-message": "required-email" }
              })
            ])
          : _vm._e(),
        _vm._t("default"),
        _c("p", {
          staticClass: "input-helper",
          attrs: { "validate-message": "form" }
        }),
        _c(
          "button",
          {
            attrs: {
              type: "submit",
              "validate-submit": "validate-submit",
              name: "form-submittter",
              value: "csrf-14713719823721983712839"
            }
          },
          [_vm._v("Check form")]
        )
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "field-body" }, [
      _c("div", { staticClass: "control" }, [
        _c("input", {
          staticClass: "input",
          attrs: {
            type: "text",
            name: "required-email",
            placeholder: "Value for placeholder",
            validate: '{"on" : ["keyup"] , "rules": ["email"]}'
          }
        })
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d70587f6", { render: render, staticRenderFns: staticRenderFns })
  }
}