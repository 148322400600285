import { Toggle } from 'bruut-toggle';
new Toggle({
    attribute: 'data-toggle',
    storageKeyPrefix: 'data-toggle__',
    defaultToggleClass: 'is-active',
    elementKey: 'who',
    classKey: 'class',
    storageKey: 'save',
    callbackKey: 'callback',
});
window['tileLinkClicked'] = function (e) {
    var overlay = document.querySelector('.detail-overlay');
    if (e.classIsSet && !overlay.classList.contains('is-active')) {
        overlay.classList.add('is-active');
    }
};
window['overlayCloseClicked'] = function (e) {
    if (!e.classIsSet) {
        var tiles = document.querySelectorAll('.tile.is-active');
        for (var i = 0; i < tiles.length; i++) {
            tiles[i].classList.remove('is-active');
        }
    }
};
window['callbackFromToggle'] = function (e) { console.log(e); };
