
	/**
	 * 1. Import your Javascript functions here
	 */
	import "./functions/responsiveImages";
	import "./functions/toggle";
	import "./functions/formValidator";
	import "./functions/fitty";

	import "./vue/app.ts";

	/**
	 * 2. Import your Pug templates to compile here
	 */
	import '../templates/home.pug';
	import '../templates/exporter.pug';

	/**
	 * 3. Import your Scss and css files here
	 */
	import '../sass/all.scss';
