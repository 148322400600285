import api from '../api';
import Vue from 'vue';
export default {
    props: [
        'id',
        'slug',
        'media',
        'name',
        'currency',
        'weather',
        'time_difference',
        'position',
        'quote',
        'title',
        'intro',
        'body',
        'visuals',
        'active'
    ],
    data: function () {
        return {
            currentTab: null,
            activities: {
                'zien': [],
                'proeven': [],
                'doen': []
            },
        };
    },
    methods: {
        setActive: function () {
            this.$store.commit('UPDATE_ACTIVE_TILE', { id: this.id, position: this.position, slug: this.slug });
            this.fetchActivities();
        },
        fetchActivities: function () {
            var _this = this;
            var _loop_1 = function (i, len) {
                var url = "poi?location=" + this_1.slug + "&type=" + this_1.$store.getters.tileDetailTabsKeys[i] + "&" + this_1.$store.state.query.sensatie.slug + "=" + this_1.$store.state.query.spullen.slug + "&omgeving=" + this_1.$store.state.query.omgeving.slug + "&orderby=random&limit=5";
                api.get(url)
                    .then(function (response) {
                    if (!response || !response.data || !response.data.results || response.data.results.length < 1) {
                    }
                    else {
                        Vue.set(_this.activities, _this.$store.getters.tileDetailTabsKeys[i], response.data.results);
                    }
                })
                    .catch(function (e) { return console.log(e); });
            };
            var this_1 = this;
            for (var i = 0, len = this.$store.getters.tileDetailTabsKeys.length; i < len; ++i) {
                _loop_1(i, len);
            }
        },
        close: function () {
            this.currentTab = null;
            this.$store.commit('UPDATE_ACTIVE_TILE', null);
        }
    },
    mounted: function () {
        this.$store.commit('UPDATE_MOUNTED_TILES');
    }
};
