import Vue from 'vue';
import store from './store/store';
import fitty from 'fitty';
import * as lazySizes from 'lazysizes';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/respimg/ls.respimg';
lazySizes.cfg.lazyClass = 'is-lazy';
lazySizes.cfg.loadingClass = 'is-loading';
lazySizes.cfg.loadedClass = 'is-loaded';
import bruutIcon from './components/bruut-icon.vue';
Vue.component('bruut-icon', bruutIcon);
import bruutForm from './components/bruut-form.vue';
Vue.component('bruut-form', bruutForm);
import tileComponent from './components/tile-component.vue';
Vue.component('tile-component', tileComponent);
import questionOverlay from './components/question-overlay.vue';
Vue.component('question-overlay', questionOverlay);
var app = new Vue({
    el: '#app',
    store: store,
    data: function () {
        return {
            fitties: null,
            form: null
        };
    },
    computed: {
        mountedTiles: function () {
            return this.$store.getters.mountedTiles;
        }
    },
    watch: {
        mountedTiles: function () {
            var _this = this;
            if (this.mountedTiles == 3) {
                setTimeout(function () { return _this.updateTitleFit(); });
            }
            ;
        }
    },
    methods: {
        removeFitties: function () {
            if (!this.fitties)
                return;
            var headers = document.querySelectorAll('.tile__header');
            for (var i = 0, len = headers.length; i < len; ++i) {
                headers[i].removeAttribute('style');
            }
            for (var i = 0, len = this.fitties.length; i < len; ++i) {
                this.fitties[i].unsubscribe();
            }
        },
        updateTitleFit: function () {
            this.fitties = new fitty('.fit', {
                multiLine: false,
                observeWindowDelay: 0
            });
            var headers = document.querySelectorAll('.tile__header');
            this.fitties[this.fitties.length - 1].element.addEventListener('fit', function (e) {
                var headerHeight = 0;
                for (var i = 0, len = headers.length; i < len; ++i) {
                    headers[i].removeAttribute('style');
                    headerHeight = Math.max(headerHeight, headers[i].offsetHeight);
                }
                for (var i = 0, len = headers.length; i < len; ++i) {
                    headers[i].style.height = headerHeight + 'px';
                }
            });
        },
        redrawTitles: function () {
            this.removeFitties();
            this.updateTitleFit();
        },
        open: function () {
            this.$store.commit('UPDATE_ACTIVE_TILE', null);
            this.$store.commit('UPDATE_QUESTION_OVERLAY', true);
        },
    },
    mounted: function () {
        lazySizes.init();
    }
});
