import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        activeTile: null,
        loading: false,
        questionFullscreen: true,
        query: {
            sensatie: null,
            spullen: null,
            omgeving: null,
        },
        queryHasResults: true,
        tileDetailTabs: {
            'zien': {
                'title': 'Zien',
                label: {
                    'culturele-bagage': '5x culturele bagage',
                    'creditcard': '5x wapperen met je creditcard',
                    'stretchbroeken': '5x je ogen uitkijken met een hapje'
                }
            },
            'proeven': {
                'title': 'Proeven',
                label: {
                    'culturele-bagage': '5x cultureel eten',
                    'creditcard': '5x riche proeven',
                    'stretchbroeken': '5x sensationeel eten en drinken'
                }
            },
            'doen': {
                'title': 'Doen',
                label: {
                    'culturele-bagage': '5x culturele dingen doen',
                    'creditcard': '5x dingen doen met geld',
                    'stretchbroeken': '5x actief genieten'
                }
            }
        },
        tiles: [
            {
                slug: 'cannes',
                id: 1,
                quote: [
                    { text: 'Cannes', large: true },
                    { text: 'is de diamant', large: false },
                    { text: 'tussen de parels', large: false },
                    { text: 'in Europa', large: true }
                ],
                visuals: {
                    large: '../dist/img/beeld/tile-1.jpg',
                }
            },
            {
                slug: 'shanghai',
                id: 2,
                quote: [
                    { text: 'Shanghai', large: true },
                    { text: 'is een stad', large: false },
                    { text: 'omgeven door', large: false },
                    { text: 'mysteries', large: true }
                ],
                visuals: {
                    large: '../dist/img/beeld/tile-2.jpg',
                },
                greeting: "Nihao!",
                teaser: "Dit vind je leuk om te weten",
                intro: "Shanghai is de grootste stad van China, het is echter niet de hoofdstad. Die eer gaat naar Beijing. Er wonen in Shanghai zo’n ruim 23 miljoen mensen, het hoort daarmee qua inwoners bij de grootste steden ter wereld. In de hoofdstad van China wonen dus meer mensen dan in heel Nederland en dat terwijl Nederland ruim vijf keer groter is!",
            },
            {
                slug: 'tbilisi',
                id: 3,
                quote: [
                    { text: 'Tbilisi', large: true },
                    { text: 'is een vertelling', large: false },
                    { text: 'uit duizend en', large: false },
                    { text: 'een nacht', large: true }
                ],
                visuals: {
                    large: '../dist/img/beeld/tile-3.jpg',
                }
            }
        ],
        system: {
            mountedTiles: 0
        }
    },
    mutations: {
        UPDATE_ACTIVE_TILE: function (state, data) {
            Vue.set(state, 'activeTile', data);
        },
        UPDATE_QUERY_HAS_RESULTS: function (state, result) {
            Vue.set(state, 'queryHasResults', result);
        },
        UPDATE_QUERY: function (state, data) {
            Vue.set(state.query, data.prop, data.val);
        },
        UPDATE_LOADING_STATE: function (state, loading) {
            Vue.set(state, 'loading', loading);
        },
        UPDATE_TILES: function (state, data) {
            Vue.set(state, 'tiles', data);
        },
        UPDATE_MOUNTED_TILES: function (state) {
            Vue.set(state.system, 'mountedTiles', state.system.mountedTiles + 1);
        },
        UPDATE_QUESTION_OVERLAY: function (state, newVal) {
            Vue.set(state, 'questionFullscreen', newVal);
        }
    },
    getters: {
        activeTileId: function (state) {
            return (state.activeTile) ? state.activeTile.id : null;
        },
        questionFullscreen: function (state) {
            return state.questionFullscreen;
        },
        activeTilePosition: function (state) {
            return (state.activeTile) ? state.activeTile.position : null;
        },
        mountedTiles: function (state) {
            return state.system.mountedTiles;
        },
        tileDetailTabsKeys: function (state) {
            return Object.keys(state.tileDetailTabs);
        }
    }
});
