import BruutValidate from 'bruut-validate';
window['bruut-validate-settings'].addRule({
    name: 'wait-before-validated',
    logic: function (rule) {
        setTimeout(function () { return rule.valid(); }, rule.args[0]);
    }
});
export default {
    data: function () {
        return {
            form: null,
            inputs: null,
            instance: null,
            toggleOpen: false
        };
    },
    mounted: function () {
        this.form = this.$el.querySelector('form');
        this.inputs = this.$el.querySelectorAll('[validate]');
        this.instance = new BruutValidate(this.form);
        this.instance.form.addEventListener('valid', function (e) { return console.log(e); });
        this.instance.form.addEventListener('invalid', function (e) { return console.log(e); });
        for (var i = 0, len = this.inputs.length; i < len; ++i) {
            this.inputs[i].addEventListener('validated', function (e) { return console.log(e); });
        }
    }
};
