var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { staticClass: "icon", class: "icon-" + _vm.name }, [
    _c("use", { attrs: { "xlink:href": "#icon-" + _vm.name } })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4c45893a", { render: render, staticRenderFns: staticRenderFns })
  }
}