import toolkit from 'bruut-toolkit';
import api from '../api';
export default {
    props: [
        'fullscreen'
    ],
    data: function () {
        return {
            changed: false,
            userHasScrolled: false,
            subHasScroll: false,
            properties: {
                'sensatie': [{ label: 'sensatie te zoeken', slug: 'sensatie' }, { label: 'te ontspannen', slug: 'ontspannen' }, { label: 'te ontdekken', slug: 'ontdekken' }],
                'spullen': [{ label: 'zwemkleding', slug: 'zwemkleding' }, { label: 'wandelschoenen', slug: 'wandelschoenen' }, { label: 'stretchbroeken', slug: 'stretchbroeken' }, { label: 'culturele bagage', slug: 'culturele-bagage' }, { label: 'creditcards', slug: 'creditcard' }, { label: 'feestneuzen', slug: 'feestneus' }],
                'omgeving': [{ label: 'rustige', slug: 'rustig' }, { label: 'levendige', slug: 'levendig' }]
            }
        };
    },
    computed: {
        questionIsAnswered: function () {
            return !!(this.$store.state.query && this.$store.state.query.sensatie && this.$store.state.query.spullen && this.$store.state.query.omgeving);
        },
        url: function () {
            return "location?" + this.$store.state.query.sensatie.slug + "=" + this.$store.state.query.spullen.slug + "&omgeving=" + this.$store.state.query.omgeving.slug + "&limit=3";
        }
    },
    methods: {
        close: function () {
            (this.changed) ? this.fetchResults() : this.$store.commit('UPDATE_QUESTION_OVERLAY', false);
        },
        fetchResults: function () {
            var _this = this;
            this.$store.commit('UPDATE_LOADING_STATE', true);
            this.$store.commit('UPDATE_QUERY_HAS_RESULTS', true);
            api.get(this.url)
                .then(function (response) {
                _this.changed = false;
                _this.$store.commit('UPDATE_LOADING_STATE', false);
                if (!response || !response.data || !response.data.results || response.data.results.length < 1) {
                    _this.$store.commit('UPDATE_QUERY_HAS_RESULTS', false);
                    console.log("helaas, we hebben geen resultaten gevonden");
                }
                else {
                    _this.$store.commit('UPDATE_QUERY_HAS_RESULTS', true);
                    _this.$store.commit('UPDATE_ACTIVE_TILE', null);
                    _this.$store.commit('UPDATE_TILES', response.data.results);
                    _this.$nextTick(function () {
                        _this.$store.commit('UPDATE_QUESTION_OVERLAY', false);
                        _this.$emit('close');
                    });
                }
            })
                .catch(function (e) { return console.log(e); });
        },
        toggleDropdown: function (event) {
            var _this = this;
            var parent = event.target.parentElement;
            var scroller = parent.querySelector('.dropdown__list');
            var handleClickOutside = function (e) {
                if (!parent.contains(e.target)) {
                    _this.$el.removeEventListener('click', handleClickOutside, true);
                    parent.classList.remove('is-active');
                }
            };
            if (parent.classList.contains('is-active')) {
                this.$el.removeEventListener('click', handleClickOutside, true);
            }
            else {
                this.$el.addEventListener('click', handleClickOutside, true);
                if (event.currentTarget.classList.contains('trigger2')) {
                    if (scroller.scrollHeight > scroller.offsetHeight) {
                        this.subHasScroll = true;
                        parent.querySelector('.dropdown__list').addEventListener('scroll', this.handleScroll);
                        console.log(scroller.scrollHeight > scroller.offsetHeight);
                        console.log(parent.offsetHeight);
                    }
                }
            }
            event.target.parentElement.classList.toggle('is-active');
        },
        handleScroll: function (e) {
            this.userHasScrolled = true;
            e.target.removeEventListener('scroll', this.handleScroll);
        },
        updateQuery: function (prop, val, event) {
            var parents = toolkit.getParentSelector(event.target, '.dropdown');
            if (parents && parents.length > 0)
                parents[0].classList.remove('is-active');
            this.$store.commit('UPDATE_QUERY', { prop: prop, val: val });
            this.changed = true;
            if (!this.fullscreen && this.questionIsAnswered) {
                this.fetchResults();
            }
        }
    },
};
