var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "question-overlay" }, [
    _c("div", { staticClass: "question-overlay__inner" }, [
      _vm.fullscreen
        ? _c("h2", { staticClass: "question" }, [
            _c("div", { staticClass: "question-layout" }, [
              _vm._v("Ik reis om "),
              _c("br", { staticClass: "is-visible-mobile" }),
              _c("div", { staticClass: "dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown__trigger trigger1",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.toggleDropdown($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        !_vm.$store.state.query.sensatie
                          ? "iets te beleven"
                          : _vm.$store.state.query.sensatie.label
                      )
                    )
                  ]
                ),
                _c(
                  "ul",
                  { staticClass: "dropdown__list" },
                  _vm._l(_vm.properties.sensatie, function(prop, index) {
                    return _c(
                      "li",
                      {
                        class: {
                          checked:
                            _vm.$store.state.query.sensatie &&
                            prop.slug == _vm.$store.state.query.sensatie.slug
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.updateQuery("sensatie", prop, $event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(prop.label))]
                        )
                      ]
                    )
                  })
                )
              ]),
              _c("br"),
              _vm._v("en neem "),
              _c("br", { staticClass: "is-visible-mobile" }),
              _c("div", { staticClass: "dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown__trigger trigger2",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.toggleDropdown($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        !_vm.$store.state.query.spullen
                          ? "spullen"
                          : _vm.$store.state.query.spullen.label
                      )
                    )
                  ]
                ),
                _c(
                  "ul",
                  { staticClass: "dropdown__list" },
                  [
                    _vm._l(_vm.properties.spullen, function(prop, index) {
                      return _c(
                        "li",
                        {
                          class: {
                            checked:
                              _vm.$store.state.query.spullen &&
                              prop.slug == _vm.$store.state.query.spullen.slug
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.updateQuery("spullen", prop, $event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(prop.label))]
                          )
                        ]
                      )
                    }),
                    !_vm.userHasScrolled && _vm.subHasScroll
                      ? _c(
                          "div",
                          { staticClass: "dropdown__scroll" },
                          [
                            _c("bruut-icon", {
                              attrs: { name: "chevron-down" }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  2
                )
              ]),
              _c("br", { staticClass: "is-visible-mobile" }),
              _vm._v(" mee "),
              _c("br", { staticClass: "is-hidden-mobile" }),
              _vm._v("naar een "),
              _c("br", { staticClass: "is-visible-mobile" }),
              _c("div", { staticClass: "dropdown" }, [
                _c(
                  "a",
                  {
                    staticClass: "dropdown__trigger trigger3",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.toggleDropdown($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        !_vm.$store.state.query.omgeving
                          ? "bepaalde"
                          : _vm.$store.state.query.omgeving.label
                      )
                    )
                  ]
                ),
                _c(
                  "ul",
                  { staticClass: "dropdown__list" },
                  _vm._l(_vm.properties.omgeving, function(prop, index) {
                    return _c(
                      "li",
                      {
                        class: {
                          checked:
                            _vm.$store.state.query.omgeving &&
                            prop.slug == _vm.$store.state.query.omgeving.slug
                        }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.updateQuery("omgeving", prop, $event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(prop.label))]
                        )
                      ]
                    )
                  })
                )
              ]),
              _c("br", { staticClass: "is-visible-mobile" }),
              _vm._v(" omgeving.")
            ]),
            _c(
              "a",
              {
                staticClass: "button question__button",
                class: {
                  "is-disabled": !_vm.questionIsAnswered || !_vm.changed
                },
                attrs: { href: "#" },
                on: { click: _vm.close }
              },
              [
                !_vm.$store.state.loading
                  ? _c("span", [_vm._v("Ontdek een plek")])
                  : _c("span", [_vm._v("We gaan nu voor je zoeken...")])
              ]
            ),
            _vm.questionIsAnswered && !_vm.$store.state.queryHasResults
              ? _c("p", { staticClass: "notification" }, [
                  _vm._v("We hebben gekeken, maar we kunnen niets vinden. "),
                  _c("br", { staticClass: "is-hidden-mobile" }),
                  _vm._v("Probeer een andere combinatie.")
                ])
              : _vm._e()
          ])
        : _c("h2", { staticClass: "question" }, [
            _c(
              "div",
              { staticClass: "question-layout is-hidden-between-small" },
              [
                _vm._v("Jij reist om "),
                _c("br", { staticClass: "is-visible-mobile" }),
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown__trigger",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.toggleDropdown($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          !_vm.$store.state.query.sensatie
                            ? "iets te beleven"
                            : _vm.$store.state.query.sensatie.label
                        )
                      ),
                      _c("bruut-icon", { attrs: { name: "chevron-down" } })
                    ],
                    1
                  ),
                  _c(
                    "ul",
                    { staticClass: "dropdown__list" },
                    _vm._l(_vm.properties.sensatie, function(prop, index) {
                      return _c(
                        "li",
                        {
                          class: {
                            checked:
                              _vm.$store.state.query.sensatie &&
                              prop.slug == _vm.$store.state.query.sensatie.slug
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.updateQuery("sensatie", prop, $event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(prop.label))]
                          )
                        ]
                      )
                    })
                  )
                ]),
                _c("br"),
                _vm._v(" met "),
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown__trigger",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.toggleDropdown($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          !_vm.$store.state.query.spullen
                            ? "spullen"
                            : _vm.$store.state.query.spullen.label
                        )
                      ),
                      _c("bruut-icon", { attrs: { name: "chevron-down" } })
                    ],
                    1
                  ),
                  _c(
                    "ul",
                    { staticClass: "dropdown__list" },
                    _vm._l(_vm.properties.spullen, function(prop, index) {
                      return _c(
                        "li",
                        {
                          class: {
                            checked:
                              _vm.$store.state.query.spullen &&
                              prop.slug == _vm.$store.state.query.spullen.slug
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.updateQuery("spullen", prop, $event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(prop.label))]
                          )
                        ]
                      )
                    })
                  )
                ]),
                _c("br", { staticClass: "is-hidden-mobile" }),
                _vm._v(" in een "),
                _c("br", { staticClass: "is-visible-mobile" }),
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "a",
                    {
                      staticClass: "dropdown__trigger",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.toggleDropdown($event)
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          !_vm.$store.state.query.omgeving
                            ? "bepaalde"
                            : _vm.$store.state.query.omgeving.label
                        )
                      ),
                      _c("bruut-icon", { attrs: { name: "chevron-down" } })
                    ],
                    1
                  ),
                  _c(
                    "ul",
                    { staticClass: "dropdown__list" },
                    _vm._l(_vm.properties.omgeving, function(prop, index) {
                      return _c(
                        "li",
                        {
                          class: {
                            checked:
                              _vm.$store.state.query.omgeving &&
                              prop.slug == _vm.$store.state.query.omgeving.slug
                          }
                        },
                        [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.updateQuery("omgeving", prop, $event)
                                }
                              }
                            },
                            [_vm._v(_vm._s(prop.label))]
                          )
                        ]
                      )
                    })
                  )
                ]),
                _vm._v(" omgeving.")
              ]
            ),
            _c(
              "div",
              { staticClass: "question-layout is-visible-between-small" },
              [_vm._v("Jouw pareltjes")]
            )
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7dd07201", { render: render, staticRenderFns: staticRenderFns })
  }
}