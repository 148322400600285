var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tile",
      class: { "is-active": _vm.active },
      attrs: { "data-position": _vm.position }
    },
    [
      _vm.media && _vm.media.landscape && _vm.media.landscape.length > 0
        ? _c("div", {
            staticClass: "is-bg is-lazy tile__bg",
            style:
              "background-position: " +
              _vm.media.landscape[0].focalPoint.percent.x +
              "% " +
              _vm.media.landscape[0].focalPoint.percent.y +
              "%",
            attrs: {
              "data-bgset":
                _vm.media.landscape[0].url +
                " " +
                _vm.media.landscape[0].width +
                "w"
            }
          })
        : _vm._e(),
      _c("div", { staticClass: "tile__detail-header" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "columns is-y-center is-x-space-between" }, [
            _vm.active
              ? _c("div", { staticClass: "column is-narrow" }, [
                  _c(
                    "a",
                    {
                      staticClass: "close",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.close($event)
                        }
                      }
                    },
                    [_c("bruut-icon", { attrs: { name: "chevron-left" } })],
                    1
                  ),
                  _c("h1", { staticClass: "city" }, [_vm._v(_vm._s(_vm.name))])
                ])
              : _vm._e(),
            _c("div", { staticClass: "column tile-detail-header__bubble" }, [
              _c("div", { staticClass: "tile__bubble-holder" }, [
                _c("div", { staticClass: "information-bubble" }, [
                  _c("div", { staticClass: "columns is-x-space-between" }, [
                    _c("div", { staticClass: "column is-narrow" }, [
                      _c("h3", { staticClass: "is-hidden-desktop-small" }, [
                        _vm._v("Temperatuur")
                      ]),
                      _c("h3", { staticClass: "is-visible-desktop-small" }, [
                        _vm._v("Graden")
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.weather))])
                    ]),
                    _c("div", { staticClass: "column is-narrow" }, [
                      _c("h3", [_vm._v("Valuta")]),
                      _c("p", [_vm._v(_vm._s(_vm.currency))])
                    ]),
                    _c("div", { staticClass: "column is-narrow" }, [
                      _c("h3", { staticClass: "is-hidden-desktop-small" }, [
                        _vm._v("Tijdsverschil")
                      ]),
                      _c("h3", { staticClass: "is-visible-desktop-small" }, [
                        _vm._v("Tijd")
                      ]),
                      _c("p", [_vm._v(_vm._s(_vm.time_difference))])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm.active
            ? _c(
                "ul",
                { staticClass: "detail-overlay__nav" },
                [
                  _c("li", { class: { "is-active": !_vm.currentTab } }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.currentTab = null
                          }
                        }
                      },
                      [_c("span", [_vm._v("Stad")])]
                    )
                  ]),
                  _vm._l(_vm.$store.state.tileDetailTabs, function(
                    tab,
                    key,
                    index
                  ) {
                    return _c(
                      "li",
                      {
                        key: key,
                        class: { "is-active": _vm.currentTab == key }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.currentTab = key
                              }
                            }
                          },
                          [_c("span", [_vm._v(_vm._s(tab.title))])]
                        )
                      ]
                    )
                  })
                ],
                2
              )
            : _vm._e()
        ])
      ]),
      _vm.active && !_vm.currentTab
        ? _c("article", { staticClass: "tile__detail-content intro" }, [
            _c("div", { staticClass: "container" }, [
              _c("h2", [_vm._v(_vm._s(_vm.title))]),
              _c("h3", [_vm._v(_vm._s(_vm.intro))]),
              _c("p", [_vm._v(_vm._s(_vm.body))])
            ])
          ])
        : _vm._e(),
      _vm.active && _vm.currentTab
        ? _c(
            "article",
            { staticClass: "tile__detail-content activity-items" },
            [
              _c("div", { staticClass: "container" }, [
                _c("h2", [
                  _vm._v(
                    _vm._s(
                      _vm.$store.state.tileDetailTabs[_vm.currentTab].label[
                        _vm.$store.state.query.spullen.slug
                      ]
                    )
                  )
                ]),
                _c(
                  "ul",
                  { staticClass: "activity-list" },
                  _vm._l(_vm.activities[_vm.currentTab], function(
                    activity,
                    index
                  ) {
                    return _c("li", { key: activity.heading }, [
                      _c("a", { attrs: { href: "#" } }, [
                        _c(
                          "div",
                          { staticClass: "columns is-gapless is-multiline" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "column is-narrow is-full-tablet"
                              },
                              [
                                _c("div", { staticClass: "visual" }, [
                                  activity.media && activity.media.length > 0
                                    ? _c("div", {
                                        staticClass: "is-bg is-lazy tile__bg",
                                        style:
                                          "background-position: " +
                                          activity.media[0].focalPoint.percent
                                            .x +
                                          "% " +
                                          activity.media[0].focalPoint.percent
                                            .y +
                                          "%",
                                        attrs: {
                                          "data-bgset":
                                            activity.media[0].url +
                                            " " +
                                            activity.media[0].width +
                                            "w"
                                        }
                                      })
                                    : _vm._e()
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "column is-full-tablet" },
                              [
                                _c("div", { staticClass: "media-text" }, [
                                  _c("h3", [_vm._v(_vm._s(activity.heading))]),
                                  _c("p", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        activity.short_description
                                      )
                                    }
                                  })
                                ])
                              ]
                            )
                          ]
                        )
                      ])
                    ])
                  })
                )
              ])
            ]
          )
        : _vm._e(),
      _c("header", { staticClass: "tile__header" }, [
        _c(
          "h2",
          [
            _vm._l(_vm.quote, function(quotePart, index) {
              return [
                _c(
                  "span",
                  {
                    key: index,
                    staticClass: "fit",
                    class: { focus: quotePart.focus == true }
                  },
                  [_vm._v(_vm._s(quotePart.text))]
                )
              ]
            })
          ],
          2
        ),
        _c(
          "a",
          {
            staticClass: "button tile__button is-hidden-desktop-small",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.setActive($event)
              }
            }
          },
          [_vm._v("Ontdek deze plek")]
        ),
        _c(
          "a",
          {
            staticClass: "button tile__button is-visible-desktop-small",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.setActive($event)
              }
            }
          },
          [_vm._v("Ontdek")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3cbca2b6", { render: render, staticRenderFns: staticRenderFns })
  }
}